/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";


.main-container{
    padding: 10px;
    width: 100%;
    padding-top: 0px;
}
.right-header{
    z-index: 9999;
    padding-top: 10px;
    padding-bottom: 5px;
}
.right-content{
    height: calc(100vh - 75px);
    overflow: auto;
    padding-top: 10px;
}